<!-- map_draw 地图标绘组件-->
<template>
    <div id="mapId"></div>
</template>

<script>
// import "leaflet/dist/leaflet.css";
// import "leaflet/dist/leaflet.js";

import "@libs/leaflet-draw/Leaflet.draw.js";
import "@libs/leaflet-draw/Leaflet.Draw.Event.js";
import "@libs/leaflet-draw/leaflet.draw.css";
import "@libs/leaflet-draw/Toolbar.js";
import "@libs/leaflet-draw/Tooltip.js";
import "@libs/leaflet-draw/ext/GeometryUtil.js";
import "@libs/leaflet-draw/ext/LatLngUtil.js";
import "@libs/leaflet-draw/ext/LineUtil.Intersect.js";
import "@libs/leaflet-draw/ext/Polygon.Intersect.js";
import "@libs/leaflet-draw/ext/Polyline.Intersect.js";
import "@libs/leaflet-draw/ext/TouchEvents.js";

import "@libs/leaflet-draw/draw/DrawToolbar.js";
import "@libs/leaflet-draw/draw/handler/Draw.Feature.js";
import "@libs/leaflet-draw/draw/handler/Draw.SimpleShape.js";
import "@libs/leaflet-draw/draw/handler/Draw.Polyline.js";
import "@libs/leaflet-draw/draw/handler/Draw.Marker.js";
import "@libs/leaflet-draw/draw/handler/Draw.Circle.js";
import "@libs/leaflet-draw/draw/handler/Draw.CircleMarker.js";
import "@libs/leaflet-draw/draw/handler/Draw.Polygon.js";
import "@libs/leaflet-draw/draw/handler/Draw.Rectangle.js";

import "@libs/leaflet-draw/edit/EditToolbar.js";
import "@libs/leaflet-draw/edit/handler/EditToolbar.Edit.js";
import "@libs/leaflet-draw/edit/handler/EditToolbar.Delete.js";
import "@libs/leaflet-draw/Control.Draw.js";

import "@libs/leaflet-draw/edit/handler/Edit.Poly.js";
import "@libs/leaflet-draw/edit/handler/Edit.SimpleShape.js";
import "@libs/leaflet-draw/edit/handler/Edit.Rectangle.js";
import "@libs/leaflet-draw/edit/handler/Edit.Marker.js";
import "@libs/leaflet-draw/edit/handler/Edit.CircleMarker.js";
import "@libs/leaflet-draw/edit/handler/Edit.Circle.js";
export default {
    name: "map_draw",
    components: {},
    props: {
        // eslint-disable-next-line
        basicMap: Object,
        // eslint-disable-next-line
        drawConf: Object,
        // eslint-disable-next-line
        jsonStr: String,
        // eslint-disable-next-line
        markerTypes: Array,
    },
    data() {
        return {
            jsonData: [],
            layerData: [],
            drawnItems: null,
            srcDefault: require("@image/monitoring_cloud/dailyPatrol/marker/otherDefault.png"),
        };
    },
    computed: {},
    watch: {
        // drawConf: function() {
        //     if (this.drawConf && this.drawConf.show) {
        //         this.initDrawControl();
        //     }
        // },
        jsonStr: function () {
            this.$nextTick(() => {
                this.getJsonData();
            });
        },
    },
    mounted() {
        this.$nextTick(() => {
            if (this.drawConf && this.drawConf.show) {
                this.initDrawControl();
            }
            this.getJsonData();
        });
    },
    destroyed() {
        if (this.drawControl) {
            this.drawnItems.clearLayers();
            this.drawControl.remove();
        }
    },
    methods: {
        // 初始化绘制工具
        initDrawControl() {
            console.log("enter initDrawControl");
            let drawConf = this.initDrawConf();
            this.drawControl = new L.Control.Draw(drawConf);
            this.basicMap.addControl(this.drawControl);
            this.drawnItems = drawConf.edit.featureGroup;
            this.$emit("updateDrawItem", this.drawnItems);
            let drawnItems = this.drawnItems;
            this.basicMap.on("draw:created", (event) => {
                let layer = event.layer;
                drawnItems.addLayer(layer);
                this.getLayerJson("created", event);
                this.$emit("updateDraw", "created", event);
            });
            this.basicMap.on("draw:deletestart", (event) => {
                this.$emit("updateDraw", "deletestart", event);
            });
            this.basicMap.on("draw:deleted", (event) => {
                this.getLayerJson("deleted", event);
                this.$emit("updateDraw", "deleted", event);
            });
            this.basicMap.on("draw:edited", (event) => {
                this.$emit("updateDraw", "edited", event);
            });
            this.basicMap.on("draw:editstop", (event) => {
                this.getLayerJson("editstop", event);
            });
        },
        // 初始化绘制配置
        initDrawConf() {
            let option1 = {
                position: this.drawConf.position || "topright",
                edit: {
                    featureGroup: window.L.featureGroup().addTo(this.basicMap),
                    poly: {
                        allowIntersection: false,
                    },
                    remove: true,
                },
                draw: {
                    polygon: true,
                    marker: {
                        icon: window.L.icon({
                            iconUrl: this.srcDefault,
                            iconSize: [17, 19],
                            iconAnchor: [9, 20],
                            popupAnchor: [0, -15],
                            bgPos: [0, 0],
                        }),
                    },
                    circle: false,
                    polyline: true,
                    rectangle: false,
                    writing: false,
                    circlemarker: false,
                },
            };
            let option2 = this.drawConf;
            $.extend(option1.draw, option2.draw);
            $.extend(option1.edit, option2.edit);
            console.log(this.drawConf, option1, "标绘配置");
            return option1;
        },
        // 获得标绘json数组
        getJsonData() {
            if (this.drawnItems) {
                this.drawnItems.clearLayers();
            }
            this.jsonData = this.jsonStr ? $.parseJSON(this.jsonStr) : [];
            this.jsonData.forEach((geo, index) => {
                // eslint-disable-next-line
                L.geoJSON(geo, {
                    style: function (feature) {
                        return {
                            color: "yellow",
                        };
                    },
                    onEachFeature: (feature, layer) => {
                        layer.on("click", () => {
                            this.$emit("clickLayer", layer);
                        });
                        if (layer.feature.geometry.type === "Point") {
                            let iconS = window.L.icon({
                                iconUrl: this.srcDefault,
                                iconSize: [17, 19],
                                iconAnchor: [0, 20],
                                popupAnchor: [0, -15],
                                bgPos: [0, 0],
                            });
                            layer.setIcon(iconS);
                        }
                        this.drawnItems.addLayer(layer);
                    },
                });
            });
        },
        // 将图层转Json
        getLayerJson(type, event) {
            let JsonList = [];
            this.drawnItems.eachLayer((e) => {
                let json = e.toGeoJSON();
                JsonList.push(json);
            });
            let JsonStr = JSON.stringify(JsonList);
            this.$emit("update:jsonStr", JsonStr, type, event);
        },
        // 删除图层
        deleteLayer(layer) {
            this.drawnItems.removeLayer(layer);
        },
    },
};
</script>
<style lang="scss" scoped>
</style>