<template>
    <div class="imgPart">
        <div
            v-for="(item, index) in imgList"
            :key="index"
            class="imgItem flex-column flex-around-y-center pointer"
            :class="[activeNum == index ? 'imgItemActive' : '']"
            @click="getTypeItem(item, index)"
        >
            <img
                class="img"
                :src="activeNum == index ? item.urlActive : item.urlDefault"
            />

            <div class="name" :class="[activeNum == index ? 'nameActive' : '']">
                {{ item.name }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "",
    props: {},
    data() {
        return {
            activeNum: 0,
            imgList: [
                {
                    name: "全部",
                    urlDefault: require("@image/monitoring_cloud/dailyPatrol/allDefault.png"),
                    urlActive: require("@image/monitoring_cloud/dailyPatrol/allActive.png"),
                    tableName: "",
                },
                {
                    name: "遗产要素",
                    urlDefault: require("@image/monitoring_cloud/dailyPatrol/ycysDefault.png"),
                    urlActive: require("@image/monitoring_cloud/dailyPatrol/ycysActive.png"),
                    tableName: "7",
                },
                {
                    name: "古茶树",
                    urlDefault: require("@image/monitoring_cloud/dailyPatrol/gcsDefault.png"),
                    urlActive: require("@image/monitoring_cloud/dailyPatrol/gcsActive.png"),
                    tableName: "307",
                },
                {
                    name: "古树名木",
                    urlDefault: require("@image/monitoring_cloud/dailyPatrol/gsmmDefault.png"),
                    urlActive: require("@image/monitoring_cloud/dailyPatrol/gsmmActive.png"),
                    tableName: "306",
                },
                {
                    name: "摄像头",
                    urlDefault: require("@image/monitoring_cloud/dailyPatrol/sxtDefault.png"),
                    urlActive: require("@image/monitoring_cloud/dailyPatrol/sxtActive.png"),
                    tableName: "116",
                },
                {
                    name: "其他",
                    urlDefault: require("@image/monitoring_cloud/dailyPatrol/othersDefault.png"),
                    urlActive: require("@image/monitoring_cloud/dailyPatrol/othersActive.png"),
                    tableName: "221",
                },
            ],
        };
    },
    created() {},
    methods: {
        getTypeItem(item, index) {
            this.activeNum = index;
            this.$emit("getTypeItem", item);
        },
    },
};
</script>
<style lang="scss" scoped>
.imgPart {
    border-radius: 10px;
    .imgItem {
        width: 70px;
        height: 70px;
        border-bottom: 1px solid rgba(24, 109, 112, 0.2);
        text-align: center;
        &:nth-child(1) {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
        &:last-child {
            border-bottom: none;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
        .img {
            margin-top: 10px;
        }
        .name {
            font-size: 12px;
            color: rgba(102, 102, 102, 1);
        }
        .nameActive {
            color: rgba(255, 255, 255, 1);
        }
    }
    .imgItemActive {
        background: rgba(24, 109, 112, 1);
    }
}
</style>