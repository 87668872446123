<template>
    <sn-dialog :dialogData.sync="dialogData">
        <el-form
            ref="ruleForm"
            :model="ruleForm"
            :rules="rules"
            label-width="100px"
            class="demo-ruleForm"
        >
            <el-form-item label="采集人" prop="SJCJFZRID">
                <SnTreeCjr
                    @handleChange="getCjrItem"
                    ref="SnTreeCjr"
                ></SnTreeCjr>
                <!-- <el-select
                    v-model="ruleForm.SJCJFZRID"
                    size="mini"
                    placeholder="采集人"
                    clearable
                    filterable
                >
                    <el-option
                        v-for="(item, index) in cjrList"
                        :key="index"
                        :label="item.REALNAME"
                        :value="item.ID"
                    ></el-option>
                </el-select> -->
            </el-form-item>
            <el-form-item class="footer">
                <sn-button
                    type="primary"
                    :snButton.sync="snButton"
                    @handleChange="handleChange"
                ></sn-button>
            </el-form-item>
        </el-form>
    </sn-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
    data() {
        return {
            ruleForm: {
                SJCJFZRID: "",
            },
            rules: {
                SJCJFZRID: [
                    {
                        required: true,
                        message: "请选择采集人",
                        trigger: "blur",
                    },
                ],
            },
            cjrList: [],
            pzids: "",
            dialogData: {
                dialog: false,
                title: "批量分配采集人",
                class: "deepBlueDialog",
            },
            snButton: {
                buttonData: [
                    {
                        btnType: "button",
                        operateType: "buttonBack",
                        name: "返回",
                        round: true,
                        backColor: "#FF9065",
                        color: "#fff",
                    },
                    {
                        btnType: "button",
                        operateType: "buttonSave",
                        name: "确定",
                        round: true,
                        backColor: "#2059CC",
                        color: "#fff",
                    },
                ],
            },
        };
    },
    methods: {
        ...mapActions(["getCjrList", "commonSaveform", "updateCjr"]),
        handleClose() {},
        showDialog(ids) {
            this.pzids = ids;
            this.initUserList();
            this.dialogData.dialog = true;
            this.ruleForm.SJCJFZRID = "";
        },
        handleChange(type) {
            switch (type) {
                case "buttonSave": //保存
                    this.saveData();
                    break;
                case "buttonBack": //返回
                    this.dialogData.dialog = false;
                    break;
            }
        },
        async saveData() {
            if (!this.ruleForm.SJCJFZRID) {
                this.common.showMsg("选择采集人", "warning");
                return;
            }
            let isValid = this.$refs["ruleForm"].validate();
            if (isValid) {
                let pzid = this.pzids.join();
                let result = await this.updateCjr({
                    pzid: pzid,
                    cjrid: this.ruleForm.SJCJFZRID,
                });
                if (result.issuccess) {
                    this.common.showMsg("分配采集人成功", "success");
                    this.dialogData.dialog = false;
                    this.pzids = [];
                    this.$emit("saveSuccess");
                } else {
                    this.common.showMsg("分配采集人失败", "error");
                }
            }
            this.ruleForm.SJCJFZRID = "";
            this.$emit("removeAllotLayer");
        },
        async initUserList() {
            this.cjrList = await this.getCjrList({
                funid: "f1b6f5b2-de6e-11e9-81a5-000c2977b7fd",
            });
        },
        getCjrItem(id) {
            this.ruleForm.SJCJFZRID = id;
        },
    },
};
</script>
<style lang="scss" scoped>
.title {
    height: 30px;
    border-bottom: 1px dashed #bacbdf;
    color: #0053c4;
}

.demo-ruleForm {
    .el-form-item {
        margin-bottom: 15px;
        .short-input {
            width: 180px;
        }
    }
    .footer {
        border-top: 1px dashed #bacbdf;
        text-align: right;
        padding-top: 20px;
    }
}
</style>