<template>
    <div id="daily-patrol" class="daily-patrol">
        <taskCommon
            title="管护区"
            :currentTree="currentTree"
            :currentId="currentId"
            :list="listAry"
            :snSearch="snSearch"
            :taskMap="taskMap"
            :legendList="legendList"
            :listConfig="listConfig"
            @handleChange="handleChange"
        >
            <slot>
                <Mymap
                    :mapData="mapData"
                    class="map"
                    ref="myMap"
                    @initmap="initMap"
                ></Mymap>
                <PointDialog
                    ref="dialog"
                    :dialogData.sync="dialogData"
                    :zoneId="currentTree"
                    @saveSuccess="getPatrolList"
                ></PointDialog>
                <!-- <AreaDialog
                    ref="dialogArea"
                    :dialogData.sync="dialogDataArea"
                    :zoneId="currentTree"
                    @saveSuccess="getPatrolList"
                ></AreaDialog> -->
                <AllotDialog
                    ref="allotDialog"
                    @removeAllotLayer="removeAllotLayer"
                    @saveSuccess="getPatrolList"
                ></AllotDialog>
                <MapDraw
                    ref="myMapDraw"
                    :basicMap.sync="taskMap"
                    :drawConf.sync="drawConf"
                    :markerTypes="ztArr"
                    @update:jsonStr="getJsonStr"
                    @updateDrawItem="updateDrawItem"
                    @updateDraw="updateDraw"
                >
                </MapDraw>
                <!-- <div class="sideBarPart">
                    <SideBar @getTypeItem="getTypeItem"></SideBar>
                </div> -->
                <div class="legendPart flex-y-center">
                    <div class="title">图例</div>
                    <div class="flex list">
                        <div
                            v-for="(item, index) in legendList"
                            :key="`${index}_com`"
                            @click="legendChange(item)"
                        >
                            <div
                                v-if="item.imgState"
                                class="flex-y-center legendItem pointer"
                            >
                                <img
                                    :src="
                                        item.state ? item.src : item.srcDefault
                                    "
                                    class="img"
                                />
                                <div class="name">{{ item.name }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </slot>
        </taskCommon>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import mixins from "../mixins";
import mixins1 from "./ancient_tea_tree_mixins";
import PointDialog from "./comp/PointDialog"; //配置点
// import AreaDialog from "./comp/AreaDialog"; //区域
import AllotDialog from "./comp/AllotDialog";
import SideBar from "./comp/SideBar"; //右侧筛选按钮
import MapDraw from "./comp/MapDraw";
export default {
    name: "daily-patrol",
    components: {
        PointDialog,
        AllotDialog,
        // AreaDialog,
        MapDraw,
        SideBar,
    },
    mixins: [mixins, mixins1],
    data() {
        return {
            listConfig: {
                name: "label",
                value: "id",
                children: "trees"
            },
            listAry: [], //巡查列表
            mapData: {
                dxtState: true, //地形图
                maxZoom: 20,
                zoom: 12.5,
                areaState: true,
            },
            currentId: 30701,
            markersLayer: null,
            showNewButton: false,
            json: "",
            drawConf: {
                position: "topleft",
                show: true,
                draw: {},
            },
            dialogDataArea: {
                title: "",
                dialog: false,
                class: "deepBlueDialog",
            },
            pointList: [],
            allotLayer: null,
            pointJson: [],
            isMoving: false,
            isFirstinPolygon: true,
            myPolygon: null,
            operateMarkerGroup: null, //操作图层组
            editedMarker: null,
            ztArr: [],
            drawnItems: null,
            tableName: "", //表名字 用于右侧搜索左侧的tree
            // zt "" 0 未配置 其余 已配置 1实施中 2 暂停3 终止
            legendList: [
                // {
                //     name: "遗产要素已配置",
                //     typeName: "遗产要素",
                //     src: require("@image/monitoring_cloud/dailyPatrol/marker/ycysActive.png"),
                //     srcDefault: require("@image/monitoring_cloud/dailyPatrol/marker/ycysDefault.png"),
                //     group: null,
                //     list: [],
                //     tclx: "7", //图层类型
                //     ztList: ["1", "2", "3"], //已配置
                //     state: true,
                //     imgState: true,
                // },
                // {
                //     name: "遗产要素未配置",
                //     typeName: "遗产要素",
                //     src: require("@image/monitoring_cloud/dailyPatrol/marker/ycysError.png"),
                //     srcDefault: require("@image/monitoring_cloud/dailyPatrol/marker/ycysDefault.png"),
                //     group: null,
                //     list: [],
                //     tclx: "7",
                //     ztList: ["0", ""], //未配置
                //     state: true,
                //     imgState: true,
                // },
                {
                    name: "古茶树已配置",
                    typeName: "古茶树",
                    src: require("@image/monitoring_cloud/dailyPatrol/marker/gcsActive.png"),
                    srcDefault: require("@image/monitoring_cloud/dailyPatrol/marker/gcsDefault.png"),
                    group: null,
                    list: [],
                    // tclx: "307",
                    tclx: "30701",
                    ztList: ["1", "2", "3"], //已配置
                    state: true,
                    imgState: true,
                },
                {
                    name: "古茶树未配置",
                    typeName: "古茶树",
                    src: require("@image/monitoring_cloud/dailyPatrol/marker/gcsError.png"),
                    srcDefault: require("@image/monitoring_cloud/dailyPatrol/marker/gcsDefault.png"),
                    group: null,
                    list: [],
                    // tclx: "307",
                    tclx: "30701",
                    ztList: ["0", ""], //未配置
                    state: true,
                    imgState: true,
                },
                // {
                //     name: "古树名木已配置",
                //     typeName: "古树名木",
                //     src: require("@image/monitoring_cloud/dailyPatrol/marker/gsmmActive.png"),
                //     srcDefault: require("@image/monitoring_cloud/dailyPatrol/marker/gsmmDefault.png"),
                //     group: null,
                //     list: [],
                //     tclx: "306",
                //     ztList: ["1", "2", "3"], //已配置
                //     state: true,
                //     imgState: true,
                // },
                // {
                //     name: "古树名木未配置",
                //     typeName: "古树名木",
                //     src: require("@image/monitoring_cloud/dailyPatrol/marker/gsmmError.png"),
                //     srcDefault: require("@image/monitoring_cloud/dailyPatrol/marker/gsmmDefault.png"),
                //     group: null,
                //     list: [],
                //     tclx: "306",
                //     ztList: ["0", ""], //未配置
                //     state: true,
                //     imgState: true,
                // },
                // {
                //     name: "摄像头已配置",
                //     typeName: "摄像头",
                //     src: require("@image/monitoring_cloud/dailyPatrol/marker/sxtActive.png"),
                //     srcDefault: require("@image/monitoring_cloud/dailyPatrol/marker/sxtDefault.png"),
                //     group: null,
                //     list: [],
                //     tclx: "116",
                //     ztList: ["1", "2", "3"], //已配置
                //     state: true,
                //     imgState: true,
                // },
                // {
                //     name: "摄像头未配置",
                //     typeName: "摄像头",
                //     src: require("@image/monitoring_cloud/dailyPatrol/marker/sxtError.png"),
                //     srcDefault: require("@image/monitoring_cloud/dailyPatrol/marker/sxtDefault.png"),
                //     group: null,
                //     list: [],
                //     tclx: "116",
                //     ztList: ["0", ""], //未配置
                //     state: true,
                //     imgState: true,
                // },
                // {
                //     name: "其他已配置",
                //     typeName: "其他",
                //     src: require("@image/monitoring_cloud/dailyPatrol/marker/otherActive.png"),
                //     srcDefault: require("@image/monitoring_cloud/dailyPatrol/marker/otherDefault.png"),
                //     group: null,
                //     list: [],
                //     tclx: "221",
                //     ztList: ["1", "2", "3"], //已配置
                //     state: true,
                //     imgState: true,
                // },
                // {
                //     name: "其他未配置",
                //     typeName: "其他",
                //     src: require("@image/monitoring_cloud/dailyPatrol/marker/otherError.png"),
                //     srcDefault: require("@image/monitoring_cloud/dailyPatrol/marker/otherDefault.png"),
                //     group: null,
                //     list: [],
                //     tclx: "221",
                //     ztList: ["0", ""], //未配置
                //     state: true,
                //     imgState: true,
                // },
            ],
            markerPopup: null,
        };
    },
    created() {},
    computed: {
        ...mapGetters(["system"]),
    },
    mounted() {
        this.markerPopup = window.L.popup({
            closeOnClick: false,
            closeButton: false,
            offset: [0, 0],
            className: "markerPopup",
        });

        this.setButton();
        this.getManageAreaData();
    },
    methods: {
        ...mapActions([
            "GetRcxcQyListData",
            "deleteJcpz",
            "updateLatLng",
            "GetManageAreaData",
            "GetGcsqdData",
        ]),
        // 清空图层组
        clearLayersGroup(group) {
            if (this.common.containGroup(group)) {
                group.clearLayers();
            }
        },
        setButton() {
            let buttonData = this.snSearch.searchBtn.buttonData;
            let allotButton = {
                btnType: "button",
                operateType: "buttonAllot",
                name: "批量分配采集人",
                round: true,
                backColor: "#2a63d6",
                color: "#fff",
            };
            buttonData.unshift(allotButton);
        },
        handleSelfChange(type, value) {
            this[type](value);
        },
        // 标绘操作
        getJsonStr(JsonStr, type, event) {
            let jsonStr = JSON.parse(JsonStr);
            this.clearLayersGroup(this.operateMarkerGroup);
            if (jsonStr.length === 0) return false;
            let json = jsonStr[jsonStr.length - 1].geometry;
            this.pointJson = jsonStr;
            switch (event.type) {
                case "draw:created": // 新建
                    if (event.layerType === "polygon") {
                        // 绘制区域
                        this.createpolygon(event.layer, json); // 批量分配采集人
                    } else if (event.layerType === "marker") {
                        // 绘制巡查点
                        // this.createMarker(event.layer);
                    }
                    break;
                case "draw:editstop": // 新建
                    this.updateDrawGeo();
                    break;
                default:
                    break;
            }
        },
        // 移动保存在 getJsonStr 中获取不到，用这个
        updateDrawGeo() {
            let layer = this.editedMarker;
            let item = layer.options.data;
            let latlng = layer.getLatLng();
            let geojson = {
                type: "Point",
                coordinates: [latlng.lng, latlng.lat],
            };
            for (let i = 0; i < this.pointList.length; i++) {
                let oneMark = this.pointList[i];
                if (latlng.lng === oneMark.lng && latlng.lat === oneMark.lat) {
                    if (item.id == oneMark.id) {
                        this.common.showMsg(
                            "移动后的巡查点不能与原有巡查点重合",
                            "warning"
                        );
                        return;
                    }
                }
            }
            this.xcdxList = this.isInPolygon(layer);
            let area = this.xcdxList[0];
            if (area) {
                this.updateJeojson(item, geojson);
            } else {
                this.common.showMsg("请在巡查区内移动巡查点！", "error");
                this.getPatrolList();
            }
        },
        updateDraw(type, event) {},
        async updateJeojson(item, geoJson) {
            let res = await this.updateLatLng({
                jcwzid: item.id,
                qyid: item.pid,
                geoJson: geoJson,
            });
            if (res.issuccess) {
                this.common.showMsg("巡查点移动成功", "success");
            } else {
                this.common.showMsg("巡查点移动失败", "error");
            }
            this.editedMarker = null;
            this.getPatrolList();
        },
        // 获取巡查列表
        async getPatrolListRcxc() {
            let depmentid = "";
            if (this.system == "system2") {
                depmentid = localStorage.departmentId;
            }
            this.legendList.map((item) => {
                item.list = [];
                this.clearLayersGroup(item.group);
            });

            this.listAry = await this.GetRcxcQyListData({
                depmentid: depmentid,
                tclx: this.tableName,
            });
            // mixins.js 点击地图 进入页面 这个变量 有值
            this.list = this.listAry;
            let pointArr = [];
            this.listAry.forEach((item) => {
                if (item.child && item.child.length > 0) {
                    item.child.forEach((item1) => {
                        // 遗产要素7 古茶树307 古树名木306 摄像头116 其他221
                        let list = ["7", "307", "306", "116"];
                        if (list.includes(item1.tclx)) {
                            item1.lat = item1.wd;
                            item1.lng = item1.jd;
                            item1.geojson = JSON.stringify({
                                type: "Point",
                                coordinates: [item1.jd, item1.wd],
                            });
                        } else {
                            if (item1.geojson) {
                                // this.isJSON(item1.geojson);
                                let geojson = JSON.parse(item1.geojson);
                                item1.lat = geojson.coordinates[1];
                                item1.lng = geojson.coordinates[0];
                                item1.jd = geojson.coordinates[1];
                                item1.wd = geojson.coordinates[0];
                            }
                        }
                        pointArr.push(item1);
                        // zt "" 0 未配置 其余 已配置 1实施中 2 暂停3 终止
                        this.legendList.map((item2) => {
                            if (
                                item1.tclx == item2.tclx &&
                                item2.ztList.includes(item1.zt)
                            ) {
                                item2.list.push(item1);
                            }
                        });
                        if (
                            this.$route &&
                            this.$route.params &&
                            this.$route.params.value &&
                            this.$route.params.value === item1.jcpzid
                        ) {
                            this.taskMap.setView([item1.lat, item1.lng], 17);
                        }
                    });
                }
            });
            this.pointList = pointArr;
            // this.getAreaData(); // 加载日常巡查的片区
            this.drawPatrolPoint(this.legendList);
        },
        async getPatrolList() {
            let _this = this;
            let depmentid = "";
            _this.listAry = [];
            if (_this.system == "system2") {
                depmentid = localStorage.departmentId;
            }
            _this.legendList.map((item) => {
                item.list = [];
                _this.clearLayersGroup(item.group);
            });

            const result = await _this.GetGcsqdData();
            if (result && result.resultvalue && result.resultvalue.length > 0) {
                _this.listAry = result.resultvalue;
                _this.list = _this.listAry;
                let pointArr = [];
                _this.listAry.forEach((item) => {
                    if (item.trees && item.trees.length > 0) {
                        item.trees.forEach((item1) => {
                            // 遗产要素7 古茶树307 古树名木306 摄像头116 其他221
                            // let list = ["7", "307", "306", "116"];
                            // if (list.includes(item1.tclx)) {
                            //     item1.lat = item1.wd;
                            //     item1.lng = item1.jd;
                            //     item1.geojson = JSON.stringify({
                            //         type: "Point",
                            //         coordinates: [item1.jd, item1.wd],
                            //     });
                            // } else {
                            //     if (item1.geojson) {
                            //         // _this.isJSON(item1.geojson);
                            //         let geojson = JSON.parse(item1.geojson);
                            //         item1.lat = geojson.coordinates[1];
                            //         item1.lng = geojson.coordinates[0];
                            //         item1.jd = geojson.coordinates[1];
                            //         item1.wd = geojson.coordinates[0];
                            //     }
                            // }
                            item1.lat = item1.bw;
                            item1.lng = item1.dj;
                            item1.geojson = JSON.stringify({
                                type: "Point",
                                coordinates: [item1.dj, item1.bw],
                            });
                            pointArr.push(item1);
                            // zt "" 0 未配置 其余 已配置 1实施中 2 暂停3 终止
                            _this.legendList.map((item2) => {
                                if (item2.ztList.includes(item1.zt)) {
                                item2.list.push(item1);
                                }
                            });
                            if (
                                _this.$route &&
                                _this.$route.params &&
                                _this.$route.params.data &&
                                _this.$route.params.data.jcdxid &&
                                _this.$route.params.data.jcdxid === item1.id
                            ) {
                                _this.taskMap.setView(
                                    [item1.bw, item1.dj],
                                    17
                                );
                            }
                        });
                    }
                });
                _this.pointList = pointArr;
                // _this.getAreaData(); // 加载日常巡查的片区
                _this.drawPatrolPoint(_this.legendList);
            }
        },
        isJSON(str) {
            if (typeof str == "string") {
                try {
                    JSON.parse(str);
                    return true;
                } catch (e) {
                    console.log(str);
                    return false;
                }
            }
        },
        // 获取巡查区数据
        getAreaData() {
            this.clearLayersGroup(this.patrolZoneLayer);
            let newList = []; //新的列表
            let hcqList = []; //缓冲区的点位
            this.listAry.forEach((zone) => {
                if (zone.label === "景迈山古茶林") {
                    hcqList.push(zone);
                } else if (zone.geojson) {
                    newList.push(zone);
                }
            });
            let objKey = {
                value: "value",
                label: "label",
                geojson: "geojson",
                color: "ys",
            };
            // 缓冲区
            this.drawArea(this.patrolZoneLayer, hcqList, 1, objKey);
            // 非缓冲区
            this.drawArea(this.patrolZoneLayer, newList, 0, objKey);
        },
        // 获取管护区列表
        async getManageAreaData() {
            this.clearLayersGroup(this.manageProtectAreaLayer);
            const result = await this.GetManageAreaData();
            if (result && result.resultvalue && result.resultvalue.length > 0) {
                let objKey = {
                    value: "id",
                    label: "qymc",
                    geojson: "qyzb",
                    color: "ys",
                };
                this.drawArea(
                    this.manageProtectAreaLayer,
                    result.resultvalue,
                    0,
                    objKey
                );
            }
        },
        // 画巡查区num 1 缓冲区 0 非缓冲区
        drawArea(group, list, num, objKey) {
            list.forEach((zone) => {
                let geoJson = JSON.parse(zone[objKey.geojson]);
                window.L.geoJSON(geoJson, {
                    onEachFeature: (feature, layer) => {
                        layer.data = zone;
                        layer.setStyle({
                            color:
                                num == 0 ? zone[objKey.color] : "rgba(0,0,0,0)",
                            fillColor: zone[objKey.color],
                            fillOpacity: num == 0 ? 0.3 : 0,
                            weight: 2,
                        });
                        layer.on("mouseover", (e) => {
                            this.markerPopup
                                .setLatLng([e.latlng.lat, e.latlng.lng])
                                .setContent(
                                    `<div class="tip">${
                                        zone[objKey.label]
                                    }</div>`
                                )
                                .openOn(this.taskMap);
                        });
                        layer.on("mousemove", (e) => {
                            this.markerPopup
                                .setLatLng([e.latlng.lat, e.latlng.lng])
                                .setContent(
                                    `<div class="tip">${
                                        zone[objKey.label]
                                    }</div>`
                                )
                                .openOn(this.taskMap);
                        });
                        layer.on("mouseout", () => {
                            this.markerPopup.removeFrom(this.taskMap);
                        });
                        // layer.on("click", () => {
                        //     //点击左侧巡查区
                        //     this.changeArea(zone);
                        //     this.highLightPatrolZone(zone[objKey.value]);
                        // });
                    },
                }).addTo(group);
            });
        },
        // 片区高亮
        highLightPatrolZone(value) {
            Object.values(this.patrolZoneLayer._layers).forEach((item) => {
                Object.values(item._layers).forEach((item1) => {
                    if (item1.data.value === value) {
                        item1.setStyle({
                            fillColor: "#28ccd9",
                            color: "#28ccd9",
                        });
                    }
                });
            });
        },
        // 画巡查点
        drawPatrolPoint(list) {
            this.clearLayersGroup(this.markersLayer);
            list.map((item) => {
                this.clearLayersGroup(item.group);
                this.drowMarkerItem(item);
            });
        },
        // 点击图例显隐对应的图层
        legendChange(item) {
            item.state = !item.state;
            if (item.state) {
                this.drowMarkerItem(item);
            } else {
                Object.values(item.group._layers).forEach((layer) => {
                    this.markersLayer.removeLayer(layer);
                });
                this.clearLayersGroup(item.group);
            }
        },
        drowMarkerItem(item) {
            // 遗产要素7 古茶树307 古树名木306 摄像头116 其他221
            item.list.map((item1) => {
                let icon = window.L.icon({
                    iconUrl: item.src,
                    iconSize: [20, 20],
                    iconAnchor: item1.tclx == "221" ? [0, 20] : [10, 10],
                });
                if (item1.lat && item1.lng) {
                    let marker = window.L.marker([item1.lat, item1.lng], {
                        icon: icon,
                        data: item1,
                    }).addTo(item.group);
                    this.markersLayer.addLayer(marker);
                    marker.bindPopup(item1.label, {
                        closeButton: false,
                        closeOnClick: true,
                        offset: item1.tclx == "221" ? [10, -20] : [0, -5],
                    });
                    this.setMarkerEvents(marker);
                }
            });
        },
        // 新建巡查点
        creatMonitor() {
        },
        // 新建巡查区
        creatArea() {
            let option = {
                allowIntersection: false,
                showArea: false,
            };
            this.isFirstinPolygon = false;
            // eslint-disable-next-line
            this.myPolygon = new L.Draw.Polygon(this.taskMap, option);
            this.myPolygon.enable();
        },
        //点击左侧列表 首先调用这个方法
        changeList(val) {
            let patrolName = val[1];
            //  点击左侧巡查点
            if (val[4] && !val[4].trees) {
                this.$refs.myMap.changeFitBounds(val[4].lat, val[4].lng);
                this.currentTree = val[4].id;
                this.dialogData.title = `${patrolName}-古茶树监测配置`;
                this.$refs.dialog.getDetail(val[4]);
                this.dialogData.dialog = true;
                this.markerPopup
                    .setLatLng([val[4].lat, val[4].lng])
                    .setContent(`<div class="tip">${patrolName}</div>`)
                    .openOn(this.taskMap);
            }
        },
        // // 点击巡查区域
        // changeArea(val) {
        //     this.currentTree = val.value;
        //     this.dialogDataArea.title = `${val.label}-古茶树监测配置`;
        //     this.$refs.dialogArea.getDetail(val);
        //     this.dialogDataArea.dialog = true;
        // },
        updateDrawItem(draw) {
            this.markersLayer = draw;
            this.getPatrolList();
            this.legendList.map((item) => {
                item.group = window.L.layerGroup().addTo(this.taskMap);
            });
        },
        // 类型筛选
        getTypeItem(obj) {
            this.legendList.map((item) => {
                if (obj.name == "全部") {
                    item.imgState = true;
                } else if (item.typeName == obj.name) {
                    item.imgState = true;
                } else {
                    item.imgState = false;
                }
            });
            this.tableName = obj.tableName;
            this.getPatrolList();
        },
    },
};
</script>
<style lang="scss" scoped>
.daily-patrol {
    position: relative;
    .sideBarPart {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        width: 70px;
        z-index: 400;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0 0 20px rgba(23, 109, 111, 0.12);
        border-radius: 10px;
    }
    .legendPart {
        position: absolute;
        bottom: 10px;
        left: 300px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0 0 20px rgba(23, 109, 111, 0.12);
        z-index: 400;
        .title {
            width: 20px;
            height: 56px;
            line-height: 23px;
            background: #186d70;
            font-weight: 400;
            font-size: 12px;
            color: #d8eaf6;
            text-align: center;
            padding-top: 5px;
        }
        .list {
            padding-left: 10px;
            height: 100%;
            .legendItem {
                margin-right: 10px;
                .img {
                    margin-right: 5px;
                }
                .name {
                    font-weight: 400;
                    font-size: 12px;
                    color: #666666;
                }
            }
        }
    }
}
</style>