<template>
    <sn-dialog :dialogData.sync="dialogData">
        <el-form
            ref="ruleForm"
            :model="ruleForm.data[0]"
            :rules="rules"
            label-width="110px"
            class="demo-ruleForm"
        >
            <div class="tea-tree-info">
                <div class="tea-inline-item">
                    <div>
                        <label for="">茶树编号</label>
                        <span>{{ teaTreeInfo.bh }}</span>
                    </div>
                    <div>
                        <label for="">资源名称</label>
                        <span>{{ teaTreeInfo.szzwm }}</span>
                    </div>
                    <div>
                        <label for="">别名</label>
                        <span>{{ teaTreeInfo.szbm }}</span>
                    </div>
                    <div>
                        <label for="">海拔高度</label>
                        <span>{{ teaTreeInfo.hb }}</span>
                    </div>
                </div>
                <div>
                    <label for="">生长地点</label>
                    <span>{{ teaTreeInfo.szdd }}</span>
                </div>
                <div class="tea-inline-item">
                    <div>
                        <label for="">所属管护区</label>
                        <span>{{ teaTreeInfo.qymc }}</span>
                    </div>
                    <div>
                        <label for="">负责人</label>
                        <span>{{ teaTreeInfo.cjrxm }}</span>
                    </div>
                </div>
            </div>

            <div :class="btnItemShow ? 'has-bottom-border' : ''">
                <el-form-item label="名称" prop="Jcpzfamc">
                    <el-input
                        v-model="ruleForm.data[0].JcpzDtos.Jcpzfamc"
                        size="mini"
                    ></el-input>
                </el-form-item>
                <el-form-item label="监测周期" prop="Zq">
                    <el-input
                        v-model="ruleForm.data[0].JcpzDtos.Zq"
                        size="mini"
                        class="short-input"
                        maxlength="5"
                        @input="formatZq"
                    ></el-input>
                    <el-radio-group v-model="ruleForm.data[0].JcpzDtos.Zqdw">
                        <el-radio :label="3">日</el-radio>
                        <el-radio :label="2">周</el-radio>
                        <el-radio :label="1">月</el-radio>
                        <el-radio :label="0">年</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="采集人" prop="Sjcjfzrid">
                    <SnTreeCjr
                        @handleChange="getCjrItem"
                        ref="SnTreeCjr"
                    ></SnTreeCjr>
                </el-form-item>
                <!-- <el-form-item label-width="130px" label="是否立即下发任务" prop="Rwsfbhdqzq">
                    <el-radio-group v-model="ruleForm.data[0].JcpzDtos.Rwsfbhdqzq">
                        <el-radio label="1">是</el-radio>
                        <el-radio label="0">否</el-radio>
                    </el-radio-group>
                </el-form-item> -->
            </div>
            <el-form-item v-if="btnItemShow" class="footer">
                <sn-button
                    type="primary"
                    :snButton.sync="snButton"
                    @handleChange="handleChange"
                ></sn-button>
            </el-form-item>
        </el-form>
    </sn-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "",
    props: {
        dialogData: {
            default() {
                return {};
            },
        },
        zoneId: {
            type: String,
            default() {
                return "";
            },
        },
        btnItemShow: {
            type: Boolean,
            default() {
                return true;
            },
        },
    },
    data() {
        return {
            // ruleForm: {},
            ruleForm: {
                itemCode: "30701",
                jcdxId: "",
                isCustomJcwz: 0,
                data: [
                    {
                        JcwzDto: {
                            Id: "",
                            Zbsjid: "",
                            Jcwzmc: "",
                            Jcwzsytmc: null,
                            Jcwzsytlj: null,
                            Jcwzms: null,
                            Px: null,
                            Ycysbm: null,
                            Jcdxbid: "307",
                            YDDJZTMC: null,
                            YDDJZTLJ: null,
                            Jcwzdtxx: {
                                Id: "",
                                Sjid: "",
                                Zbxx: "",
                            },
                            Dwlx: 1, // 0 打卡点， 1 采集点
                            Sflyjcdx: null,
                            Jd: null,
                            Wd: null,
                        },
                        JcpzDtos: {
                            Id: "", //监测配置Id
                            Jcpzfamc: "", //监测配置方案名称
                            Zq: "", //周期
                            Zqdw: 3, //周期单位
                            Lrfs: 2, //录入方式(2移动端录入)
                            Sjcjfzrid: "", //数据采集负责人Id,
                            Rwsfbhdqzq: 1, //任务是否包含当前周期(1是)
                            Zt: "1", //状态(1实施中)
                            Sfszqxjc: "1", //是否是周期性监测(1固定周期),
                        },
                    },
                ],
            },
            rules: {
                // Zq: [
                //     {
                //         required: true,
                //         message: "请输入采集周期",
                //         trigger: "blur",
                //     },
                //     {
                //         min: 1,
                //         max: 5,
                //         message: "长度不能超过5",
                //         trigger: "blur",
                //     },
                // ],
            },
            snButton: {
                buttonData: [
                    {
                        btnType: "button",
                        operateType: "buttonBack",
                        name: "返回",
                        round: true,
                        backColor: "#FF9065",
                        color: "#fff",
                    },
                    {
                        btnType: "button",
                        operateType: "buttonSave",
                        name: "确定",
                        round: true,
                        backColor: "#2059CC",
                        color: "#fff",
                    },
                ],
            },
            teaTreeInfo: {},
        };
    },
    computed: {
        ...mapGetters(["system"]),
    },
    // created() {
    //     this.ruleForm = JSON.parse(JSON.stringify(this.ruleBasicForm));
    // },
    methods: {
        ...mapActions(["commonSaveform", "getCorridorForm"]),
        async getDetail(item) {
            console.log("getDetail-item: ", item);
            let _this = this;
            _this.teaTreeInfo = JSON.parse(JSON.stringify(item));
            _this.ruleForm.data[0].JcwzDto.Zbsjid = item.id;
            _this.ruleForm.data[0].JcwzDto.Jcwzmc = item.label;
            _this.ruleForm.data[0].JcwzDto.Jcwzdtxx.Zbxx = item.geojson;

            _this.ruleForm.data[0].JcpzDtos.Jcpzfamc = item.label + "监测";
            _this.ruleForm.data[0].JcpzDtos.Zq = "";
            _this.ruleForm.data[0].JcpzDtos.Sjcjfzrid = "";
            if (_this.$refs.SnTreeCjr) {
                _this.$refs.SnTreeCjr.setLabelName(null);
            }

            if (!item.id) {
                return;
            }

            // 遗产要素 古茶树 古树名木 摄像头 内置的第一次没有id
            let res = await _this.getCorridorForm({
                id: 30701,
                jgslid: item.id,
            });
            let result = res && res.jcwzAndJcpzDtos && res.jcwzAndJcpzDtos[0];

            if (res && res.OtherData && res.OtherData[0]) {
                _this.teaTreeInfo = {
                    ..._this.teaTreeInfo,
                    ...res.OtherData[0],
                };
                if (!_this.ruleForm.data[0].JcwzDto.Jcwzmc) {
                    _this.ruleForm.data[0].JcwzDto.Jcwzmc =
                        _this.teaTreeInfo.bh;
                }
            }
            _this.ruleForm.data[0].JcwzDto = {
                ..._this.ruleForm.data[0].JcwzDto,
                ...result.JcwzDto,
            };
            _this.ruleForm.data[0].JcpzDtos = {
                ..._this.ruleForm.data[0].JcpzDtos,
                ...result.JcpzDtos,
            };
            if (!_this.ruleForm.data[0].JcpzDtos.Jcpzfamc) {
                _this.ruleForm.data[0].JcpzDtos.Jcpzfamc =
                    _this.ruleForm.data[0].JcwzDto.Jcwzmc + "监测";
            }
            if (_this.$refs.SnTreeCjr) {
                if (_this.ruleForm.data[0].JcpzDtos.Sjcjfzrid) {
                    _this.$refs.SnTreeCjr.setLabelName(
                        _this.ruleForm.data[0].JcpzDtos.Sjcjfzrid
                    );
                } else {
                    _this.$refs.SnTreeCjr.setLabelName(null);
                }
            }
        },
        initCjrData(id) {
            let cjrId = this.ruleForm.data[0].JcpzDtos.Sjcjfzrid;
            if (!cjrId) {
                this.ruleForm.data[0].JcpzDtos.Sjcjfzrid = id;
            }
        },
        async handleChange(type) {
            switch (type) {
                case "buttonSave":
                    this.saveData();
                    break;
                default:
                    this.dialogData.dialog = false;
                    break;
            }
        },
        async saveData() {
            let _this = this;
            if (!_this.ruleForm.data[0].JcpzDtos.Zq) {
                _this.common.showMsg("填写周期", "warning");
                return;
            }
            if (!_this.ruleForm.data[0].JcpzDtos.Sjcjfzrid) {
                _this.common.showMsg("选择采集人", "warning");
                return;
            }
            let valid = await _this.$refs["ruleForm"].validate();
            if (valid) {
                if (
                    _this.ruleForm.data[0].JcpzDtos.Sjcjfzrid &&
                    _this.ruleForm.data[0].JcpzDtos.Zq
                ) {
                    _this.ruleForm.data[0].JcpzDtos.Zt = 1; // 启动任务
                } else {
                    _this.ruleForm.data[0].JcpzDtos.Zt = 0;
                }
                _this.ruleForm.jcdxId = _this.teaTreeInfo.id;
                
                // 判断是否“周”的周期ZQDW==“2”，判断是否周五、六、日，
                // 若是，RWSFBHDQZQ值为“0”，不立即下发任务，下个周期再下发
                if (+_this.ruleForm.data[0].JcpzDtos.Zqdw === 2) {
                    let dayArr = [5, 6, 0];
                    if (dayArr.indexOf(new Date().getDay()) !== -1) {
                        _this.ruleForm.data[0].JcpzDtos.Rwsfbhdqzq = 0;
                    }
                }

                let result = await _this.commonSaveform(_this.ruleForm);
                if (result.IsSuccess) {
                    _this.common.showMsg("保存成功！", "success");
                    _this.dialogData.dialog = false;
                    _this.$emit("saveSuccess");
                } else {
                    _this.common.showMsg("保存失败！", "success");
                }
            } else {
                _this.common.showMsg("请检查是否填写完整！", "warning");
            }
        },
        getCjrItem(id) {
            this.ruleForm.data[0].JcpzDtos.Sjcjfzrid = id;
        },
        formatZq() {
            this.ruleForm.data[0].JcpzDtos.Zq =
                this.ruleForm.data[0].JcpzDtos.Zq.replace(
                    /[^0-9]/g,
                    ""
                ).replace(/^0*/g, "");
        },
    },
};
</script>
<style lang="scss" scoped>
.demo-ruleForm {
    .tea-tree-info {
        line-height: 30px;
        padding: 0 10px 10px;
        border-bottom: 1px dashed #bacbdf;
        label {
            color: #606266;
            font-weight: bold;
        }
        span {
            padding: 0 20px 0 5px;
        }
        .tea-inline-item {
            display: flex;
        }
    }
    .el-form-item {
        margin: 15px 0;
        .short-input {
            // width: calc(100% - 200px);
            width: 178px;
        }
        .el-switch {
            width: 160px;
        }
        .el-switch.is-checked .el-switch__core {
            border-color: #2b64d7;
            background-color: #2b64d7;
        }
        .el-radio-group {
            // float: right;
            margin-top: 0px;
            margin-left: 15px;
            .el-radio {
                margin-right: 10px;
                span.el-radio__label {
                    // TODO 不生效
                    padding-left: 2px !important;
                }
            }
        }
    }
    .has-bottom-border {
        border-bottom: 1px dashed #bacbdf;
    }
    .footer {
        // border-top: 1px dashed #BACBDF;
        text-align: right;
        padding-top: 20px;
    }
}
</style>
