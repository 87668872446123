import { render, staticRenderFns } from "./AllotDialog.vue?vue&type=template&id=d51dbc0e&scoped=true&"
import script from "./AllotDialog.vue?vue&type=script&lang=js&"
export * from "./AllotDialog.vue?vue&type=script&lang=js&"
import style0 from "./AllotDialog.vue?vue&type=style&index=0&id=d51dbc0e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d51dbc0e",
  null
  
)

export default component.exports