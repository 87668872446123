export default {
    components: {},
    props: {},
    data() {
        return {
            drawnItemsPatrolMap: null,
            patrolZoneLayer: null, //巡查区图层组
            manageProtectAreaLayer: null, // 管护区图层组
            isMoving: false,
        };
    },
    mounted() {
        // 初始化地图图层
        this.drawnItemsPatrolMap = window.L.featureGroup().addTo(this.taskMap);
        this.patrolZoneLayer = window.L.featureGroup().addTo(this.taskMap);
        this.manageProtectAreaLayer = window.L.featureGroup().addTo(this.taskMap);
        this.allotLayer = window.L.featureGroup().addTo(this.drawnItemsPatrolMap);
        this.operateMarkerGroup = window.L.layerGroup().addTo(this.taskMap);
    },
    methods: {
        // 新建地图标记
        // createMarker(layer) {
        //     let latLng = layer.getLatLng();
        //     for (let i = 0; i < this.pointList.length; i++) {
        //         let oneMark = this.pointList[i];
        //         if (latLng.lng === oneMark.lng && latLng.lat === oneMark.lat) {
        //             this.common.showMsg("新增巡查区点不能与已有巡查点重合", "warning")
        //             return;
        //         }
        //     }
        //     this.xcdxList = this.isInPolygon(layer);
        //     let area = this.xcdxList[0];
        //     if (area) {
        //         let item = area.data
        //         item.pid = item.value
        //         item.tclx = "221"
        //         item.jd = latLng.lng
        //         item.wd = latLng.lat
        //         this.$refs.dialog.getYcysdt();
        //         this.dialogData.title = item.label + '-古茶树监测配置';
        //         this.dialogData.dialog = true;
        //         this.$refs.dialog.getDetail(item);
        //         layer.selfType = 'marker';
        //         this.editedMarker = layer;
        //         this.setMarkerEvents(layer);
        //     }
        // },
        // 判断包含该点的面
        isInPolygon(layer) {
            let latLng = layer.getLatLng();
            let data = [];
            Object.values(this.patrolZoneLayer._layers).forEach((item) => {
                Object.values(item._layers).forEach((item1) => {
                    let result = this.isPointInPolygon(item1, latLng);
                    if (result) {
                        data.push(item1);
                    }
                });
            })
            return data;
        },
        // 判断点是否在面中-处理坐标
        isPointInPolygon(polygon, point) {
            return turf.booleanPointInPolygon(turf.point([point.lng, point.lat]), polygon.feature);
        },
        // 绘制区域
        createpolygon(layer, json) {
            let poligen = {
                feature: {
                    type: "Feature",
                    geometry: json,
                },
            };
            let ids = [];
            this.pointList.forEach(item => {
                if (item.lat && item.lng) {
                    let res = this.isPointInPolygon(poligen, { lat: item.lat, lng: item.lng });
                    if (res) {
                        ids.push(item.jcpzid);
                    }
                }
            });
            this.allotLayer.addLayer(layer);
            if (ids.length > 0) {
                this.$refs.allotDialog.showDialog(ids);
            } else {
                this.allotLayer.clearLayers();
                this.common.showMsg('当前绘制的区域内没有巡查点', 'warning')
            }
        },
        // 取消 / 保存批量分配采集人，完成后把原有区域清除掉
        removeAllotLayer() {
            this.allotLayer.clearLayers();
        },
        // 巡查点的事件
        setMarkerEvents(marker) {
            marker.on("mouseover", () => {
                marker.openPopup();
            });
            marker.on("mouseout", () => {
                marker.closePopup();
            });
            marker.on('click', (ev) => {
                window.L.DomEvent.stopPropagation(ev);
                this.clearLayersGroup(this.operateMarkerGroup)
                    // 点击地图标记，显示地图标记操作按钮：移动、配置、删除
                let location = marker.getLatLng();
                let item = marker.options.data
                if (!this.isMoving) {
                    if (item.tclx == "221") {
                        window.L.marker([location.lat, location.lng], {
                            icon: window.L.divIcon({
                                className: "operate-maker",
                                html: `<div class='circle circle3'><i></i><i></i><i></i><span class='move operate-item el-icon-rank' title='移动'></span><span class='operate operate-item el-icon-setting' title='配置'></span><span class='delete operate-item el-icon-delete' title='删除'></span></div>`,
                                iconsize: [90, 90],
                                iconAnchor: [36, 55]
                            })
                        }).addTo(this.operateMarkerGroup)
                    } else {
                        this.$refs.dialog.getDetail(item);
                        this.dialogData.title = item.label + '-古茶树监测配置';
                        this.dialogData.dialog = true;
                        this.clearLayersGroup(this.operateMarkerGroup)
                    }
                }
                this.isMoving = false;
                $(".operate-maker .delete").on("click", () => {
                    this.deleteTask(item.jcpzid);
                    this.clearLayersGroup(this.operateMarkerGroup)
                });
                $(".operate-maker .operate").on("click", () => {
                    this.$refs.dialog.getDetail(item);
                    this.dialogData.title = item.label + '-古茶树监测配置';
                    this.dialogData.dialog = true;
                    this.clearLayersGroup(this.operateMarkerGroup)
                });
                $(".operate-maker .move").on("click", () => {
                    this.isMoving = true;
                    $(".leaflet-draw-edit-edit")[0].click();
                    $(".leaflet-draw-tooltip span")[0].innerHTML = '拖动完成编辑';
                    $(".leaflet-draw-tooltip span")[1].innerHTML = '';
                    this.clearLayersGroup(this.operateMarkerGroup)
                });
            });
            marker.on('mouseup', () => {
                if (this.isMoving) {
                    this.editedMarker = marker;
                    $(".leaflet-draw-actions a[title='保存操作']")[0].click();
                }
            });
        },
        // 删除巡查点
        deleteTask(value) {
            this.$confirm('是否永久删除该条记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                .then(() => {
                    this.delData(value)
                })
                .catch(() => {
                    this.common.showMsg("已取消删除", "info")
                });
        },
        // 确认删除
        async delData(id) {
            let result = await this.deleteJcpz({
                jcpzid: id
            });
            if (result.issuccess) {
                this.common.showMsg("删除成功", "success")
                this.getPatrolList();
            } else {
                this.common.showMsg("删除失败", "error")
            }
        },
        // 批量分配采集人
        buttonAllot() {
            let option = {
                allowIntersection: false,
                showArea: false
            };
            this.isFirstinPolygon = false;
            this.myPolygon = new L.Draw.Polygon(this.taskMap, option);
            this.myPolygon.enable();
        },
    },
    destroyed() {
        this.patrolZoneLayer.clearLayers();
        this.manageProtectAreaLayer.clearLayers();
        this.drawnItemsPatrolMap.clearLayers();
        this.patrolZoneLayer = null;
        this.manageProtectAreaLayer = null;
        this.drawnItemsPatrolMap = null;
        this.taskMap = null;
    }
};